import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import LoadingScreen from './components/LoadingScreen';
import { GuardClient, AuthClient, GuardAdmin } from './guards';
import AdminUserVerificationDetail from './pages/Admin/AdminUserVerificationDetail';
import AdminDepositBolivares from './pages/Admin/AdminDepositBolivares';
import Landing from './pages/Landing';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loadable(lazy(() => import('./pages/Login')))
const LogoutSecure = Loadable(lazy(() => import('./pages/LogoutSecure')))
const UserDashboard = Loadable(lazy(() => import('./pages/UserDashboardNew')))
const Register = Loadable(lazy(() => import('./pages/Register')))
const ProfilePage = Loadable(lazy(() => import('./pages/ProfilePage')))
const ProfileDepositDetail = Loadable(lazy(() => import('./pages/ProfileDepositDetail')))
//const AcademyPage = Loadable(lazy(() => import('./pages/AcademyPage')))
//const AcademyDetailPage = Loadable(lazy(() => import('./pages/AcademyDetailPage')))
//const AcademyHomePage = Loadable(lazy(() => import('./pages/AcademyHomePage')))
const AboutUsPage = Loadable(lazy(() => import('./pages/AboutUsPage')))
//const AcademyHomeDetailPage = Loadable(lazy(() => import('./pages/AcademyHomeDetailPage')))
const Recover = Loadable(lazy(() => import('./pages/Recover')))
const MyAccounts = Loadable(lazy(() => import('./pages/MyAccounts')))
const MyAccountEdit = Loadable(lazy(() => import('./pages/MyAccountEdit')))
const CustomerService = Loadable(lazy(() => import('./pages/CustomerService')))
const NewDeposit = Loadable(lazy(() => import('./pages/DepositNew')))
const BlogPage = Loadable(lazy(() => import('./pages/BlogPage')))
const BlogDetailPage = Loadable(lazy(() => import('./pages/BlogDetailPage')))
const NewDetailPage = Loadable(lazy(() => import('./pages/NewDetailPage')))
const VerifyAccountPage = Loadable(lazy(() => import('./pages/VerifiyAccountPage')))
const SecurityPage = Loadable(lazy(() => import('./pages/SecurityPage')))

const AdminDashboard = Loadable(lazy(() => import('./pages/Admin/AdminDashboard')))
const AdminFiat = Loadable(lazy(() => import('./pages/Admin/Fiat')))
const AdminFiatEdit = Loadable(lazy(() => import('./pages/Admin/FiatEdit')))
const AdminProcessors = Loadable(lazy(() => import('./pages/Admin/ProcessorsList')))
const AdminProcessorsreate = Loadable(lazy(() => import('./pages/Admin/ProcessorsCreate')))
const AdminProcessorsEdit = Loadable(lazy(() => import('./pages/Admin/ProcessorsEdit')))
const AdminProcessorsFiat = Loadable(lazy(() => import('./pages/Admin/ProcessorsFiat')))
const AdminAccountBank = Loadable(lazy(() => import('./pages/Admin/AccountBankList')))
const AdminAccountBankSave = Loadable(lazy(() => import('./pages/Admin/AccountBankSave')))
const AdminDeposit = Loadable(lazy(() => import('./pages/Admin/Deposit')))
const AdminDepositDetail = Loadable(lazy(() => import('./pages/Admin/DepositDetail')))
const AdminDepositTime = Loadable(lazy(() => import('./pages/Admin/DepositTime')))

const AdminUserList = Loadable(lazy(() => import('./pages/Admin/UserList')))
const AdminRolePage = Loadable(lazy(() => import('./pages/Admin/RolePage')))
const AdminUserDetail = Loadable(lazy(() => import('./pages/Admin/AdminUserDetail')))
const AdminUserVerification = Loadable(lazy(() => import('./pages/Admin/AdminUserVerificationList')))
const AdminBlog = Loadable(lazy(() => import('./pages/Admin/Blog/AdminBlog')))
const AdminBlogSave = Loadable(lazy(() => import('./pages/Admin/Blog/AdminBlogSave')))
const PolicyPage = Loadable(lazy(() => import('./pages/PolicyPage')))
const TermsPage = Loadable(lazy(() => import('./pages/Terms')))

const routes: PartialRouteObject[] = [
  
  {
    path: '/login',
    children: [
      {
        path: '/login',
        element: (
          <AuthClient><Login /></AuthClient>
        )
      },
    ]
  },
  {
    path: '/',
    element: (<Landing />)
  },

  // =============================================================
  // ROUTES CLIENT  
  // =============================================================
  {
    path: '/inicio',
    element: (<GuardClient><UserDashboard /></GuardClient>)
  },
  {
    path: '/logout-secure',
    element: (<GuardClient><LogoutSecure /></GuardClient>)
  },  
  {
    path: '/inicio/:tab',
    element: (<GuardClient><UserDashboard /></GuardClient>)
  },
  {
    path: '/register',
    element: (<AuthClient><Register /></AuthClient>)
  },
  {
    path: '/perfil',
    element: (<GuardClient><ProfilePage /></GuardClient>)
  },
  {
    path: '/perfil/:tab',
    element: (<GuardClient><ProfilePage /></GuardClient>)
  },
  {
    path: '/perfil/retiros',
    element: (<GuardClient><ProfilePage /></GuardClient>)
  },
  {
    path: '/perfil/depositos',
    element: (<GuardClient><ProfilePage /></GuardClient>)
  },
  {
    path: '/transferencia',
    element: (<ProfileDepositDetail />)
  },
  {
    path: '/transferencia/:depositId',
    element: (<GuardClient><ProfileDepositDetail /></GuardClient>)
  },
  {
    path: '/perfil/chat',
    element: (<GuardClient><ProfilePage /></GuardClient>)
  },
  {
    path: '/perfil/notificaciones',
    element: (<GuardClient><ProfilePage /></GuardClient>)
  },
  /*{
    path: '/aprender',
    element: (<GuardClient><AcademyPage /></GuardClient>)
  },
  {
    path: '/aprender/detalle/:id',
    element: (<GuardClient><AcademyDetailPage /></GuardClient>)
  },
  {
    path: '/academia',
    element: (<AcademyHomePage />)
  },*/
  {
    path: '/quienes-somos',
    element: (<AboutUsPage />)
  },  
  /*{
    path: '/academia/detalle/:id',
    element: (<AcademyHomeDetailPage />)
  },*/ 
  {
    path: '/recover',
    element: (<Recover />)
  },
  {
    path: '/mis-cuentas',
    element: (<GuardClient><MyAccounts /></GuardClient>)
  },
  {
    path: '/mis-cuentas/:proccessorId',
    element: (<GuardClient><MyAccounts /></GuardClient>)
  },  
  {
    path: '/mis-cuentas/editar',
    element: (<GuardClient><MyAccountEdit /></GuardClient>)
  },
  {
    path: '/atencion-al-cliente',
    element: (<GuardClient><CustomerService /></GuardClient>)
  },
  {
    path: '/transferir/',
    element: (<GuardClient><NewDeposit /></GuardClient>)
  },  
  /*{
    path: '/deposita/detalle/:id',
    element: (<GuardClient><DepositDetailUser /></GuardClient>)
  }, */
  {
    path: '/verificar-cuenta/:id',
    element: (<VerifyAccountPage />)
  },
  {
    path: '/seguridad',
    element: (<GuardClient><SecurityPage /></GuardClient>)
  },
  {
    path: '/noticia/:slug',
    element: (<GuardClient><NewDetailPage /></GuardClient>)
  },  
  {
    path: '/blog',
    element: (<BlogPage />)
  },
  {
    path: '/blog/:slug',
    element: (<BlogDetailPage />)
  },  
  {
    path: '*',
    element: (<Landing />)
  },

   {/* <Routes>
  <Route path="/" element={<Landing />} />

  <Route path="/login" element={<Login />} />

  <Route path="/inicio" element={<UserDashboard />} />

  <Route path="/register" element={<Register />} />

  <Route path="/perfil" element={<ProfilePage />} />
  
  <Route path="/perfil/retiros" element={<ProfilePage />} />

  <Route path="/perfil/depositos" element={<ProfilePage />} />
 
  <Route path="/perfil/detalle/retiro/:withdrawId" element={<ProfileWithdrawDetail />} />

  <Route path="/perfil/chat" element={<ProfilePage />} />
  
  <Route path="/perfil/notificaciones" element={<ProfilePage />} />

  <Route path="/academia" element={<AcademyPage />} />

  <Route path="/aprender" element={<AcademyHomePage />} />


  <Route path="/recover" element={<Recover />} />

  <Route path="/mis-cuentas" element={<MyAccounts />} />

  <Route path="/mis-cuentas/editar" element={<MyAccountEdit />} />

  <Route path="/atencion-al-cliente" element={<CustomerService />} />

  <Route path="/depositos" element={<DepositPage />} />

  <Route path="/verificar-cuenta/:id" element={<VerifyAccountPage />} />

  <Route path="/retiros" element={<WithdrawPage />} />
  
  <Route path="/seguridad" element={<SecurityPage />} />

  <Route path="/blog" element={<BlogPage />} />

</Routes> */},
  

  // =================================================
  // ROUTES ADMIN 
  // =================================================
  {
    path: '/admin',
    element: (<GuardAdmin><AdminDashboard /></GuardAdmin>)
  },
  {
    path: '/admin/fiat',
    element: (<GuardAdmin><AdminFiat /></GuardAdmin>)
  },
  {
    path: '/admin/fiat/editar',
    element: (<GuardAdmin><AdminFiatEdit /></GuardAdmin>)
  },
  {
    path: '/admin/procesadores',
    element: (<GuardAdmin><AdminProcessors /></GuardAdmin>)
  },
  {
    path: '/admin/procesadores/crear',
    element: (<GuardAdmin><AdminProcessorsreate /></GuardAdmin>)
  },
  {
    path: '/admin/procesadores/editar',
    element: (<GuardAdmin><AdminProcessorsEdit /></GuardAdmin>)
  },
  {
    path: '/admin/procesadores/fiat',
    element: (<GuardAdmin><AdminProcessorsFiat /></GuardAdmin>)
  },
  {
    path: '/admin/cuentas-bancarias',
    element: (<GuardAdmin><AdminAccountBank /></GuardAdmin>)
  },
  {
    path: '/admin/cuentas-bancarias/guardar',
    element: (<GuardAdmin><AdminAccountBankSave /></GuardAdmin>)
  },
  {
    path: '/admin/depositos',
    element: (<GuardAdmin><AdminDeposit /></GuardAdmin>)
  },
  {
    path: '/admin/depositos-bolivares',
    element: (<GuardAdmin><AdminDepositBolivares /></GuardAdmin>)
  },  
  {
    path: '/admin/depositos/detalle',
    element: (<GuardAdmin><AdminDepositDetail /></GuardAdmin>)
  },
  {
    path: '/admin/depositos/detalle/:transferId',
    element: (<GuardAdmin><AdminDepositDetail /></GuardAdmin>)
  },
  {
    path: '/admin/depositos/tiempo',
    element: (<GuardAdmin><AdminDepositTime /></GuardAdmin>)
  },
  {
    path: '/admin/usuario/lista',
    element: (<GuardAdmin><AdminUserList /></GuardAdmin>)
  },
  {
    path: '/admin/roles',
    element: (<GuardAdmin><AdminRolePage /></GuardAdmin>)
  },  
  {
    path: '/admin/usuario/detalle/:id',
    element: (<GuardAdmin><AdminUserDetail /></GuardAdmin>)
  },
  {
    path: '/admin/verificaciones',
    element: (<GuardAdmin><AdminUserVerification /></GuardAdmin>)
  },  
  {
    path: '/admin/verificaciones/:id',
    element: (<GuardAdmin><AdminUserVerificationDetail /></GuardAdmin>)
  },    
  {
    path: '/admin/blog',
    element: (<GuardAdmin><AdminBlog /></GuardAdmin>)
  },
  {
    path: '/admin/blog/guardar',
    element: (<GuardAdmin><AdminBlogSave /></GuardAdmin>)
  },
  {
    path: '/politica-de-privacidad',
    element: (<PolicyPage />)
  },
  {
    path: '/terminos-y-condiciones',
    element: (<TermsPage />)
  },
  {/* 
    
    <Route path="/admin" element={<AdminDashboard />} />

    <Route path="/admin/fiat" element={<AdminFiat />} />

    <Route path="/admin/fiat/editar" element={<AdminFiatEdit />} />

    <Route path="/admin/procesadores" element={<AdminProcessors />} />

    <Route path="/admin/procesadores/crear" element={<AdminProcessorsreate />} />

    <Route path="/admin/procesadores/editar" element={<AdminProcessorsEdit />} />

    <Route path="/admin/procesadores/fiat" element={<AdminProcessorsFiat />} />

    <Route path="/admin/cuentas-bancarias" element={<AdminAccountBank />} />

    <Route path="/admin/cuentas-bancarias/guardar" element={<AdminAccountBankSave />} />

    <Route path="/admin/depositos" element={<AdminDeposit />} />
    
    <Route path="/admin/depositos/detalle" element={<AdminDepositDetail />} />

    <Route path="/admin/depositos/detalle/:depositId" element={<AdminDepositDetail />} />

    <Route path="/admin/depositos/tiempo" element={<AdminDepositTime />} />

    <Route path="/admin/retiros" element={<AdminWithdrawList />} />

    <Route path="/admin/retiros/detalle/:withdrawId" element={<AdminWithdrawDetail />} />

    <Route path="/admin/usuario/lista" element={<AdminUserList />} />

    <Route path="/admin/usuario/detalle/:userId" element={<AdminUserDetail />} />

    <Route path="/admin/blog" element={<AdminBlog />} />

    <Route path="/admin/blog/guardar" element={<AdminBlogSave />} />

    <Route path="/PolicyPage" element={<PolicyPage />} />

    <Route path="/rules" element={<Rules />} />

*/},
 
]



export default routes;