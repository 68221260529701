import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

type confirmationProps = {
  confirm(): void 
  cancel(): void 
  show: boolean
}
const Confirmation = ( {confirm,cancel,show}:confirmationProps ) => {
 
  const { t } = useTranslation();


  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative "  style={{zIndex: 1000}} onClose={cancel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {t('confirmation')}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                    {t('aresure')}
                    </p>
                  </div>

                  <div className="mt-4">
                  <button
                      type="button"
                      className="inline-flex mr-3 justify-center rounded-md border  border-dark-green px-4 py-2 text-sm font-medium text-black"
                      onClick={()=>confirm()}
                    >
                      {t('confirm')}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border bg-dark-green text-white px-4 py-2 text-sm font-medium"
                      onClick={()=>cancel()}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export default Confirmation