
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaExchangeAlt } from 'react-icons/fa';
import { GoHome } from 'react-icons/go';
import { AiOutlineUser } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdOutlineLogout, MdOutlineSecurity } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { UseAuth } from 'src/auth/AuthContext';
import Confirmation from '../Shared/Confirmation';
import { BsFillKeyFill } from 'react-icons/bs';
import { RiBankCardFill } from 'react-icons/ri';
import { classNames, validateRouteRole } from 'src/helpers/mixins';

interface Props {
  showMenu: boolean;
  closeMenu:() => void
}


const SideBar = ({ showMenu, closeMenu }: Props) => {

  const { logout, user } = UseAuth()
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const location = useLocation();
  const pathName = location.pathname.toString()

  const role = user?.role || "user";
  const enableRoutes = validateRouteRole(role);
  
  const handleLogout = () => {
    logout()
  }
  return (
    <div className={`${!showMenu && 'hidden md:block'} w-full md:w-fit  bg-green-admin flex-shrink flex-grow-0 shadow-md min-h-screen`} style={{ zIndex: 900 }}>
      <div className={'sticky bg-white h-full top-0 md:my-4 transition-all'} >
        <ul className='flex flex-col overflow-hidden content-center justify-between text-center'>    
        <li className='block md:hidden'>
          <div onClick={() => closeMenu()}  className=' bg-green-admin hover:bg-green-admin-light flex flex-col items-center justify-center text-white  border-b border-[#2f463f] hover:text-white py-4 px-1 font-light'  >
            <div className='flex flex-wrap text-white items-center text-xs font-semibold text-lg text-center gap-6'>
              <FaArrowLeft size={27} /> {t('exitmenu')}
            </div>
            <span className="text-xs text-white">{t('return')}</span>
          </div>
        </li>        
          <LiDev 
            name={t('sidebar.home')} 
            to="/inicio"
            pathName={pathName}
            Icon={<><GoHome size={30} className='text-white' /></>}
          />
          <LiDev 
            name={t('sidebar.profile')} 
            to="/perfil"
            pathName={pathName}
            Icon={<><AiOutlineUser size={30} className='text-white' /></>}
          />
          <LiDev 
            name={t('sidebar.newtransfer')} 
            to="/transferir"
            pathName={pathName}
            Icon={<><FaExchangeAlt size={30} className='text-white' /></>}
          />   
          <LiDev 
            name={t('sidebar.account')} 
            to="/mis-cuentas"
            pathName={pathName}
            Icon={<><RiBankCardFill size={30} className='text-white' /></>}
          />                   
          {/*<LiDev 
            name={t('sidebar.academy')} 
            to="/aprender"
            pathName={pathName}
            Icon={<><FaGraduationCap size={30} className='text-white' /></>}
          /> */}     
          <LiDev 
            name={t('sidebar.security')} 
            to="/seguridad"
            pathName={pathName}
            Icon={<><MdOutlineSecurity size={30} className='text-white' /></>}
          />
          <LiDev 
            name={t('sidebar.support')} 
            to="/atencion-al-cliente"
            pathName={pathName}
            Icon={<><IoLogoWhatsapp size={30} className='text-white' /></>}
          />
          {(enableRoutes.includes("*") || enableRoutes.includes("/admin")) && (
            <LiDev 
              name={t('sidebar.admin')} 
              to="/admin"
              pathName={pathName}
              Icon={<><BsFillKeyFill size={30} className='text-white' /></>}
            />
           )}
          <li  className='flex flex-row items-center justify-center bg-green-admin hover:bg-green-admin-light items-center py-4 px-3 '>
            <Link className='flex flex-col items-center py-3 text-white font-light' to='' >
              <div onClick={() => setShowConfirmation(true)} className='flex flex-col gap-2 items-center text-xs'>
                <div>
                  <MdOutlineLogout size={32} className='text-white' />
                </div>
                <div>
                  <span className='text-base tracking-widest md:tracking-normal		 md:text-xs text-white break-all'>{t('exit')}</span>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
        <Confirmation show={showConfirmation} cancel={()=>setShowConfirmation(false)} confirm={() => handleLogout()} />
    </div>
  )
}
export default SideBar

const LiDev = ({ name, to, Icon,pathName }: any) => {

  return (
    <li>
      <Link  className={ classNames( (pathName.toString()).includes(to) ? 'bg-green-admin-light' : ' transition-all bg-green-admin hover:bg-green-admin-light ','flex flex-col items-center justify-center text-white  border-b border-[#2f463f] hover:text-white py-4 px-0 font-light')} to={to} >
        <div className='flex flex-wrap flex-col text-white items-center text-xs text-center'>
          {Icon} 
        </div>
        <span className="text-base tracking-widest font-normal px-4	md:tracking-normal md:text-xs text-white">{name}</span>
      </Link>
    </li>
  )
}