import { useState } from "react"
import Slider from "react-slick";
import { useTranslation } from "react-i18next"
import { AiOutlineMessage } from "react-icons/ai";
type IComment = {
  text: string
}
const CustomerComments = () => {

  const { t } = useTranslation();
  const [comment] = useState<IComment[]>([
    {
      text: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fandersonarmando.caicedovargas%2Fposts%2Fpfbid0jCdGFprtBmWaF94Cv7RDqXDb8MJGF5mCHfqiTe3GEMiqi1vVuASVgLsBPuDn1STnl&show_text=true&width=500',
      },
      {
        text: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fandrea.jaramillo.7140497%2Fposts%2Fpfbid02Z3YbwQvP8XE8bpLpY6Hw69CJrq8tJtDUYzQPNZ6tqxXXwp4zVeXPjxWfZ1qjwdfEl&show_text=true&width=500',
        },
            {
              text: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0338dfEcJVcmbaFkbXjvpcpRp8dwBb6UwcFYxHFu4F5CAQxQjtGgFZunpkePcwAopvl%26id%3D100030522340557&show_text=true&width=500',
              },       
              {
                text: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FGlenn.Velazquez%2Fposts%2Fpfbid038FnxQqJ9sdxUhH6Q1xeGaA7HCaD1BGcd6qpYgYbmSuUEWjsXaj1LsHc7VSzDeFKfl&show_text=true&width=500'
              }     
              ,{
                text: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnormarisquijadaa%2Fposts%2Fpfbid02oE1Mqhq6gPe1qidfNYzucYyb3ZUX6sbhE6FB48WakeErfkpXb3Bvfag3NBLVWdQ9l&show_text=true&width=500'
              },{
                text: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjaime.rueda%2Fposts%2Fpfbid082gzrCAdAwSiv3CdFogk5LWwEaHYPhArHmzmKVKhptCAt8jgHmPj9sGSwwa3T7bNl&show_text=true&width=500'
              }
              ,{
                text: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Falexander.agudelo.52643%2Fposts%2Fpfbid02vpfsmB72AqR2KCgXDJidt8ug4KpGA9P4JvBrcVpdGkWbyZuaG3CcbjwecQcQogMkl&show_text=true&width=500'
              }

])
  const settings = {
    dots: true,
    arrows: true,
    edgeFriction: 0.35,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1014,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  }
  return (
    <section>
      <div className="text-white">
        <div className="text-center mt-6 font-light text-2xl max-w-90 mx-auto py-4 ">
          <div className="flex justify-center items-start gap-x-1">
            <h2
            className="font-light text-white">{t('comments.title')}</h2>
            <AiOutlineMessage size={30} />
          </div>
          <div 
          className="font-normal text-center text-base mt-2">

            {t('comments.subtitle')}
            <div>
              {t('informativewall.more')}{' '}{new Date().getFullYear() - 2014}{' '}{t("years")}{' '}
              {t('comments.subinfo')}
            </div>
          </div>
        </div>
        <Slider className="w-11/12 mx-auto" {...settings}>
          {comment.map((comment, key) =>
            <div
              className="mt-4 px-2 py-2"
              key={key}
            >
              <iframe 
                src={comment.text}
                height="158" 
                className="w-full rounded-lg"
                style={{"border":"none","overflow":"hidden"}} 
                scrolling="no" 
                frameBorder="0" 
                allowFullScreen={true} 
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
              </iframe>
              {/*<div
                className="text-xl flex px-4 py-2 flex-col shadow-md card-comment"
              >
                <div className="flex flex-col justify-start text-left items-start h-32">
                <ImQuotesLeft className="text-xl font-extrabold text-green" />
                  <span 
                  style={{textShadow:"0px 0.5px 1px white"}}
                  className="text-sm font-semibold">{comment.text}</span>
                </div>
                <div className="flex mt-4 items-center justify-between mb-2">
                  <div 
                  style={{textShadow:"0px 0.5px 1px white"}}
                  className="font-medium text-sm uppercase">{comment.user}</div>
                  <div>
                    <FaUserCircle size={33} />
                  </div>
                </div>
          </div>*/}
            </div>
          )}
        </Slider >

      </div>
    </section >

  )
}

export default CustomerComments