import { useEffect } from "react";
import { useState } from "react";
import Whatsapp from "../Shared/Whatsapp";
import CustomerComments from "./CustomerComments";
import Footer from "./Footer";
import VideoPresentation from "./../../assets/video/presentation.mp4";
import VideoPlaceholder from "./../../assets/video/isfvideopreview.png";
import SimulateMoney from "./SimulateMoney";
import { useTranslation } from "react-i18next";
import InformativeWall from "./InformativeWall";
import { SiTelegram } from "react-icons/si";
import { visitWhatsapp } from "../Shared/Whatsapp";
import whatsapp from "../../assets/images/whatsapp.png";
import { BiSupport } from "react-icons/bi";
import Allies from "./Allies";
import process from "process"
const BodyLanding = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [loading]);

  return (
    <div>
      {loading ? (
        <div className="skeleton-loading w-full">
          &nbsp;
          <div
            className="loading-block-dark"
            style={{ width: "90%", height: "65vh", margin: "0 auto" }}
          >
            &nbsp;
          </div>
        </div>
      ) : (
        <div className="w-11/12 mx-auto ">
          <video className="w-full" controls poster={VideoPlaceholder}>
            <source src={VideoPresentation} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
  <div className="mt-12 grid grid-cols-1 sm:grid-cols-3 gap-y-2 py-2">
        <div className="text-center">
          <div className="flex justify-center items-center ">
            <BiSupport className="text-gray-600" size={30} />
          </div>
          <div className="font-normal mb-2">{t("telegramjoin")}</div>
          <a
            target="_blank"
            href="https://t.me/ISFcambiosadmin"
            className="border border-dark-green text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-md transition-none"
            rel="noreferrer"
          >
            {t("enter")}
          </a>
        </div>
        <div className="text-center">
          <div className="flex justify-center items-center text-sky-500">
            <SiTelegram size={30} />
          </div>
          <div className="font-normal mb-2"> {t("forexjoin")} </div>
          <a
            target="_blank"
            href="https://t.me/KForexDeriv"
            className="border border-dark-green text-black px-4 py-1 font-medium hover:bg-dark-green hover:text-white rounded-md transition-none"
            rel="noreferrer"
          >
            {t("enter")}
          </a>
        </div>
        <div className="text-center text-sm w-11/12 mx-auto">
          <div className="flex justify-center items-center">
            <img src={whatsapp} className="w-8" />
          </div>
          <div className="font-normal mb-2 cursor-pointer">
            {t("comercialasesor")}
          </div>
          <span
            onClick={() => visitWhatsapp()}
            className="border border-dark-green text-black px-4 py-1 cursor-pointer font-medium hover:bg-dark-green hover:text-white rounded-md transition-none"
          >
            {t("chatwithasesor")}
          </span>
        </div>
      </div>
      {/*loading ? (
        <div className="skeleton-loading w-full">
          &nbsp;
          <div
            className="loading-block-dark"
            style={{ width: "90%", height: "65vh", margin: "0 auto" }}
          >
            &nbsp;
          </div>
        </div>
      ) : (
        <MainCarrousel />
      )*/}

      {loading ? (
        <div className="skeleton-loading w-full">
          &nbsp;
          <div
            className="loading-block-dark"
            style={{ width: "90%", height: "65vh", margin: "0 auto" }}
          >
            &nbsp;
          </div>
        </div>
      ) : (
        <SimulateMoney />
      )}


        <InformativeWall />
      {/*<div className="mt-14">
        <Community />
      </div>*/} 
      <div className="customer-say-header">
        {loading ? (
          <div className="skeleton-loading w-full">
            &nbsp;
            <div
              className="loading-block-dark"
              style={{ width: "90%", height: "65vh", margin: "0 auto" }}
            >
              &nbsp;
            </div>
          </div>
        ) : (
          <CustomerComments />
        )}
 
      </div>
        <Allies />
      <Whatsapp />
      <Footer />
    </div>
  );
};

export default BodyLanding;
