import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UseAuth } from 'src/auth/AuthContext';

interface AuthProps {
  children: ReactNode;
}

const AuthClient: FC<AuthProps> = ({ children }) => {

  const { isAuthenticated } = UseAuth();

  if (isAuthenticated) { 
    return <Navigate to="/inicio" />
  }

  return (
    <>{children}</>
  )
}

AuthClient.propTypes = {
  children: PropTypes.node
}

export default AuthClient