import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import exchangeCoin from "./../../assets/images/informativewall/transfer.gif"
import moneyFlow from "../../assets/images/money-flow.gif";
/*import { SiBitcoin } from "react-icons/si";
import deriv from "../../assets/images/deriv-icon.png";
import colombia from "../../assets/images/colombia.png";
import usa from "../../assets/images/usa.png";*/
import Select from 'react-select'
import { DepositForm  } from "../../types";
import APIService from "../../services/Api";
import { useForm } from "react-hook-form";
import FormRequired from "../Shared/FormRequired";
import { money, selectStyle } from "../../helpers/mixins";
import { FaExchangeAlt } from "react-icons/fa";
import { SelectWithdrawOptions } from "src/pages/DepositNew";
import { useAppStateContext } from "src/auth/AppStateContext";
import React from "react";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { UseAuth } from "src/auth/AuthContext";
import { Link } from "react-router-dom";
import process from "process"
 

type ISelect = {
  value: string | undefined;
  label: string;
  image: string;
}
const SimulateMoney = () => {
  const { t } = useTranslation();
  
  const { isAuthenticated } = UseAuth();

  /*const [showUsd, setShowUsd] = useState<boolean>(false);
  const [showCop, setShowCop] = useState<boolean>(true);*/
  /*const [loading, setLoading] = useState<boolean>(false);*/
  const [loadingCalculate, setLoadingCalculate] = useState<boolean>(false)
  const [useDestiny, setUseDestiny] = useState('')
  const formRef = React.useRef<HTMLFormElement>(null);
  const { fetchProccesors, processors } = useAppStateContext()
  const [notRange, setNotRange] = useState<string>("");
  const [totalToPay, setTotalToPay] = useState<string>();
  const [needValidate, setNeedValidate] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false);
  const [needRecalculate, setNeedRecalculate] = useState<boolean>(true)
  //const [typeMovement, setTypeMovement] = useState<string>();
  const [optionsDestiny, setOptionsDestiny] = useState<ISelect[]>([])
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<DepositForm>();


  useEffect(() => {
    if (!processors?.length) {
      fetchProccesors()
    }
    
    const optionsDestiny2 = processors?.filter(p => p?.status === 'active').map(p => ({
      value: p?.id,
      label: p?.name,
      image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p?.photoUrl}`
    }))
    setOptionsDestiny(optionsDestiny2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processors])

 


  const handleSelect = (type: string, e: any) => {

    switch (type) {
      case 'destiny':
        setValue('destiny', e.value)
        setUseDestiny(e.value)
        setTotalToPay(undefined)
        break;
      case 'processor':
        setValue('processor', e.value)
        setUseDestiny('')
        setTotalToPay(undefined)
        const origenFiat = getFiatName(e.value)
        let tempProcessors = [...processors]
        //para cualquier Fiat distinto a USD no está permitido q el origen y el destino contengan mismo tipo de Fiat
        if ((origenFiat || '').toUpperCase() !== ('USD')) {
          //processors?.find(p => p?.id === id)?.systemProcessorRange![0]?.systemFiat?.name
          tempProcessors = tempProcessors?.filter(p => p?.status === 'active').filter(p => p.systemProcessorRange![0]?.systemFiat?.name !== (origenFiat || ''))
        }
        const optionsDestiny2 = tempProcessors
        ?.filter(p => p.id !== e.value)
        ?.filter(p => p.status === 'active')
          ?.map(p => ({
            value: p.id,
            label: p.name,
            image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`
          }))

        setOptionsDestiny(optionsDestiny2)
        //setNeedReCalculate(true)        
        break;
      case 'accountsend':
        setValue('accountsend', e.value)
      //@ts-ignore
    }

    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }
  /*const optionsAccount = () => {
    return userAccountList?.filter(t => t?.systemProcessor?.id === getValues('destiny')).map(account => ({
      value: account.id,
      label: account.systemProcessor.name + ' ' + (account.accounttype === 'saving' ? t('accounts.saved') : t('accounts.checking')) + ' ' + account.number,
      image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${account.systemProcessor.photoUrl}`
    }))
  } */


  /*const handleCalculate = async (data: ISimulateForm) => {
    const calculate = await APIService.derivCalculate({
      money: data.money,
      processor: data.systemProcessor as string,
    });
    setNotRange("");
    if (calculate.data.status) {
      setTotalToPay("$" + money(calculate.data.data.totalToPay));
      setTypeMovement(calculate.data.data.rangePrices.systemProcessor.type);
    } else {
      setNotRange("failed");
      setTotalToPay("");
    }
  };*/

  /*const optionsDestiny = processors.filter(p => p.status ==='active')?.filter(p => p.enablewithdraw).map(p => ({
    value: p.id,
    label: p.name,
    image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`
  }))*/

  /*const optionsPay = () => {
    return processors?.filter(p => p.id !== getValues('destiny')).map(p => ({
      value: p.id,
      label: p.name,
      image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`
    }))
  }*/
  const optionsPay = () => {
    //return processors?.filter(p => p.id !== getValues('destiny')).map(p => ({
    return processors?.filter(p => p.status === 'active')?.map(p => ({
      value: p.id,
      label: p.name,
      image: `${process.env.REACT_APP_PROCESSOR_IMAGE}${p.photoUrl}`
    }))
  }
  const validateSendCalculate = () => {
    return (getValues("money") &&
      getValues("destiny") &&
      getValues("processor"))
  }

  const getFiatName = (id: any) => {
    return processors?.find(p => p?.id === id)?.systemProcessorRange![0]?.systemFiat?.name || ''
  }
  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNeedRecalculate(true)
    const { value } = e.target
    setValue('money', value)
    /*formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))*/
  }

  const goForm = () => {
    setNeedValidate(true)
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }
  const handleCalculate = async (data: DepositForm) => {

    if (validateSendCalculate()) {
      setLoadingCalculate(true)
      const calculate = await APIService.derivCalculate({
        ...data,
      });
      setNotRange("");
      if (calculate.data.status) {
        setTotalToPay(calculate.data.data.totalToPay);
        //setTypeMovement(calculate.data.data.rangePrices.systemProcessor.type);
        //agregar deposito al contexto
        setSuccess(true)
        setNeedRecalculate(false)
      } else {
        setNotRange("failed");
        setTotalToPay('0')
        setSuccess(false)
      }
      setLoadingCalculate(false)
    }
  };
  const blockInvalidChar = (e: any) => ['e', 'E', '+','.', '-', ',', '`'].includes(e.key) && e.preventDefault();

  
  return (
    <div className="text-center text-2xl sm:text-2xl mt-10 bg-gray-1 simulate-money">
      <div className="w-full mb-10 mt-10">
        <div className="text-center mx-auto text-white  md:mb-5 font-semibold drop-shadow-xl mt-5 md:mt-5 title-simulate flex justify-center items-center gap-2">
          ¡{t("exchangetext")}!
          <FaExchangeAlt />
        </div>
      </div>
      <div className="flex flex-wrap gap-6 w-11/12 mx-auto font-semibold">
        <div className="w-full md:w-2/5 flex flex-col justify-around">
          <div className="font-medium text-center text-white px-4 py-4 black-patch grid grid-cols-2">
            <div className="col-span-2  text-xl tracking-wide font-light items-center justify-center flex">
              <ul className="mb-0 flex flex-col gap-2">
                <li>
                  {t("calc.title1")}
                </li>
                <li className="flex items-center justify-center">  
                <AiFillSafetyCertificate size={43} />
                </li>
                <li>
                  {t("calc.title2")}
                </li>
                
              </ul>
            </div>            
            
          </div>
          <div className="text-base mt-2 md:mt-0 text-center md:text-left text-white px-4 py-4 black-patch font-medium grid grid-cols-2">
            <div className="text-xs col-span-2">
              <ul className="mb-0 flex flex-col gap-2">
                <li className="font-normal text-xl flex items-center justify-center">
                  {t("calc.text1")}
                </li>
                <li>  
                  <img src={moneyFlow} className="w-10 md:w-12 rounded-4xl mx-auto" alt="animacion" />
                </li>
                <li className="font-normal text-lg flex items-center justify-center">
                  {t("calc.text2")}
                </li>
              </ul>
            </div>
             
          </div>
        </div>
        <div className="w-full md:w-1/2 justify-center mb-8 md:mb-0 gap-6 black-patch px-8 py-4 h-fit">

            <div className="w-full ">
              <div className="mb-8 w-full lg:w-fit font-medium text-sm md:text-sm px-2 uppercase  mx-auto flex items-center gap-x-1 bg-title-simulate rounded-lg">
                <img src={exchangeCoin} className="w-10" alt="exchange" />
                <div className="text-center font-semibold text-sm">{t("calc.title3")}</div>
              </div>
            </div>

          <section className="text-sm">
            <form
              ref={formRef}
              onSubmit={handleSubmit(handleCalculate)}
              className="mt-2 w-full">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-5 h-auto">
                <div className="col-span-2 lg:col-span-1 text-black">
                  <div className="text-sm font-bold text-white"> {t('paymentmethodtext')} </div>
                  <Select
                    isSearchable={true}
                    options={optionsPay()}
                    components={{ Option: SelectWithdrawOptions }}
                    styles={selectStyle}
                    onChange={(e: any) => handleSelect('processor', e)}
                    placeholder={`${t('selectlist')}`}
                  />
                  {needValidate && !getValues('processor') && <FormRequired />}
                </div>
                <div className="col-span-2 lg:col-span-1 text-black">
                  <div className="text-sm font-bold text-white"> {t('needgetdestinytext')} </div>
                  {(processors?.length|| 0)  > 0 &&
                    <Select
                      isSearchable={true}
                      options={optionsDestiny}
                      components={{ Option: SelectWithdrawOptions }}
                      styles={selectStyle}
                      value={
                        {
                          label: processors?.find(p => p.id === useDestiny)?.name || undefined,
                          value: processors?.find(p => p.id === useDestiny)?.id || undefined
                        }
                      }                    
                      onChange={(e: any) => handleSelect('destiny', e)}
                      placeholder={`${t('selectlist')}`}
                    />
                  }
                  {(needValidate && !getValues('destiny')) && <FormRequired />}
                </div>
                <div className="col-span-2 lg:col-span-1 text-black">
                  <div className="text-sm font-bold text-white "> {t('willsend')} {getFiatName(getValues('processor'))}</div>
                  <input
                    onChange={changeValue}
                    onKeyDown={blockInvalidChar}
                    type="number" className="text-sm font-semibold py-4 px-2 rounded-md w-full"
                    placeholder="$ ingresa cantidad" />
                  {(errors.money || (needValidate && !getValues('money'))) && <FormRequired />}

                </div>
                <div className="col-span-2 lg:col-span-1 text-black">
                  <div className="text-sm font-bold text-white ">{t('youget')} ${getFiatName(getValues('destiny'))}</div>
                  {!loadingCalculate ?
                    (
                      <input type="text"
                        value={money(totalToPay)}
                        readOnly
                        className="text-sm font-semibold py-4 px-2 rounded-md w-full cursor-default"
                      />
                    )
                    :
                    <div className="skeleton-loading w-full">
                      <div
                        className="loading-block-dark"
                        style={{ width: "50%", height: "3rem" }}
                      >
                        &nbsp;
                      </div>
                    </div>
                  }
                </div>
                {needRecalculate &&
                  <div className="col-span-2">
                    <button type="button" 
                      onClick={() => goForm()} 
                      className="bg-green-admin-light text-white border-green-admin text-center px-12 py-1.5 text-base md:text-lg uppercase"
                    >
                      Calcular
                    </button>
                  </div>
                }
              </div>
            </form>
            {notRange === "failed" && (
              <div className="text-red-400 py-2 font-bold text-lg bg-black border border-red-400 rounded-md w-full  px-2 mt-2">
                {t("deposits.notrange")}
              </div>
            )}
            {/*<form onSubmit={handleSubmit(handleCalculate)}>
              <div className="mb-2 grid grid-cols-2">

                <div className="grid-col-1">
                  <div className="flex w-64 mb-2 mx-auto text-left items-center flex-col mt-2 text-sm">

                    <label className="font-bold text-white">
                      1. {t("paymentmethodtext")}
                    </label>
                    <select
                      disabled={isSubmitting}
                      {...register("systemProcessor", { required: true })}
                
                      onChange={(e: any) => handleSelect('processor', e)}
                      className="w-11/12 rounded-lg px-2 py-2"
                      name=""
                      id=""
                    >
                      <option value="">{t("form.chooseoption")}</option>
                      {processorList.map((processor, key) => (
                        <option key={key} value={processor.id}>
                          {processor.name}
                        </option>
                      ))}
                    </select>
                    {errors.systemProcessor && <FormRequired />}
                  </div>

                  <div>
                    <div className="w-48 text-left pr-5 flex items-center">
                      <label className="text-sm text-white font-bold w-36 px-4">
                        2. Enviarás*
                      </label>
                    </div>

                    <div className="text-sm text-white">
                      <input
                        {...register("money", { required: true })}
                        className="w-11/12 rounded-lg px-2 py-2"
                        type="number"
                      />
                    </div>
                    {errors.money && <FormRequired />}

                    {notRange === "failed" && (
                      <div className="border border-red-400 text-red-400 w-9/12 mx-auto text-xs font-light mt-2 rounded-md  px-1 py-2">
                        {t("deposits.notrange")}
                      </div>
                    )}
                  </div>
                </div>

                <div className="grid-col-1">
                  <div className="flex w-64 mb-2 mx-auto items-center flex-col mt-2 text-sm">

                    <label className="font-bold text-white">
                      3. {t("needgetdestinytext")}
                    </label>
                    <select
                      disabled={isSubmitting}
                      {...register("systemProcessor", { required: true })}

                      onChange={(e: any) => handleSelect('processor', e)}
                      className="w-11/12 rounded-lg px-2 py-2"
                      name=""
                      id=""
                    >
                      <option value="">{t("form.chooseoption")}</option>
                      {processorList.map((processor, key) => (
                        <option key={key} value={processor.id}>
                          {processor.name}
                        </option>
                      ))}
                    </select>
                    {errors.systemProcessor && <FormRequired />}
                  </div>

                 
                </div>
              </div>
              <div>
                {!isSubmitting ? (
                  <button
                    type="submit"
                    className="w-fit mx-auto bg-white text-black border border-white rounded-sm  my-3 font-bold px-4 py-1 cursor-pointer"
                  >
                    {t("calculate")}
                  </button>
                ) : (
                  <button
                    disabled={true}
                    className="w-fit flex items-center gap-x-2 mx-auto border border-white rounded-sm  my-3 text-white px-4 py-1 cursor-pointer"
                  >
                    <CgSpinner size={22} className="animate-spin" />{" "}
                    {t("loading")}
                  </button>
                )}
              </div>
            </form>*/}

 
          </section>

          {/*showUsd &&
            <section v-if="usdcop">
              <div className="mb-2">
                <div
                  className="flex flex-wrap justify-center items-baseline text-white"
                >
                  <div className="w-48 text-right pr-5 flex items-center">
                    <label className="text-base font-light w-36 px-4">
                      {t("send")}
                    </label>
                    <label className="text-base w-28 font-light flex items-center">
                    USD <img alt="usa" src={usa} className="w-5 ml-1"/>  </label >
                  </div>

                  <div className="text-base text-white">
                    <input
                      onChange={handleCop}
                      className="w-64 border border-gray-dark rounded-lg text-black px-2 py-1 font-medium"
                      type="number"
                      v-model="cop"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-center items-baseline text-white">
                <div className="w-48 text-right pr-5 flex items-center">
                  <label className="text-base font-light w-fit w-36 px-4">
                    {t("get")}
                  </label>
                  <label className="text-base w-28 font-light flex items-center">
                  COP <img alt="colombia" src={colombia} className="w-5 ml-1"/> </label >
                </div>
                <div className="text-base text-white">
                  <input
                    name="usd"
                    className="w-64 border border-gray-dark rounded-lg text-black px-2 py-1 font-medium"
                    type="number"
                    readOnly
                  />
                </div>
              </div>
            </section>
        */}
          <div className="flex w-full lg:flex-row justify-center items-center gap-x-4">
            <div className="text-center w-full mt-2 text-base text-white">
              <div>
                <i className="fa-solid fa-shield-halved"></i>
                {t("securetran")}
              </div>
              <div className="font-light text-xs">
                <i className="fa-thin fa-seal-exclamation"></i>
                {t("certifyOne")}{' '}{new Date().getFullYear() - 2014}{' '}{t("years")} 
              </div>
            </div>
          </div>

          <div className="w-full flex items-center  justify-center mt-3">
           {!isAuthenticated ?
            <Link to='/register'>
              <button  
              
              className="bg-red-500 text-white px-2 py-1 rounded-md">Crear cuenta</button>
            </Link>
              :
              <Link to="/transferir">
                <button 
                  
                  className="bg-red-500 text-white px-2 py-1 rounded-md"
                >{t('createtransfer')}</button>
              
              </Link>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default SimulateMoney;
