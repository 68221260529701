import { CSSProperties } from "react";
import { StylesConfig } from "react-select";
import { MyOptionType, Proccesor } from "src/types";
import DolarOneImage from "./../assets/images/header/b1.png"
import Compressor from "compressorjs";
type permissionsRole =  {
  type: string
  enableRoutes: string[]
}

export const money = (x: any) => {
  if (!x) x = ''
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

 
const addHours = (date:any, hours:any) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);

  return date;
}

export const getHourDate = (x:any) => {
  const date = new Date(parseInt(x))
  let hours = date.getHours() < 10 ? '0'+date.getHours() : date.getHours()
  let mins = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()
  return `${hours}:${mins}`
}
export const getHourDateString = (x:any) => {
  const date = new Date(x)
  let hours = date.getHours() < 10 ? '0'+date.getHours() : date.getHours()
  let mins = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()
  return `${hours}:${mins}`
}

export const getHourDateExpirate = (x:any,hours:any) => {
  const date = addHours(new Date(parseInt(x)), parseInt(hours));
  return date.getHours()+':'+date.getMinutes()
  //return result1
}

/*export const getFormatDate = (x:any) => {
 return new Date(parseInt(x)).toISOString().slice(0, 10);
}*/

export const getDollarOneImg = () =>{
  return DolarOneImage
}

export const getSlug = (str:string) => {
  return String(str)
  .normalize('NFKD') // split accented characters into their base characters and diacritical marks
  .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
  .trim() // trim leading or trailing whitespace
  .toLowerCase() // convert to lowercase
  .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
  .replace(/\s+/g, '-') // replace spaces with hyphens
  .replace(/-+/g, '-'); // remove consecutive hyphens
  //return name.trim().replaceAll(' ','-').replaceAll('.','').toLowerCase().replaceAll('á','a').replaceAll('é','e').replaceAll('í','i').replaceAll('ó','o').replaceAll('ú','u').replaceAll('ñ','n')
}

export const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(" ")
}

export const getMonthName = (month:any) => {
  const m = month.toString()
  switch(m){
    case '1': return 'Enero'
    case '2': return 'Febrero'
    case '3': return 'Marzo'
    case '4': return 'Abril'
    case '5': return 'Mayo'
    case '6': return 'Junio'
    case '7': return 'Julio'
    case '8': return 'Agosto'
    case '9': return 'Septiembre'
    case '10': return 'Octubre'
    case '11': return 'Noviembre'
    case '12': return 'Diciembre'
  }
}
export const compressFile = (file:File) => {
  new Compressor(file, {
    quality: 0.2,
    success: (compressedResult) => {
     return compressedResult  
    }
  });
  return undefined
}
export const getLogout = () => {
  window.location.href='/logout-secure'
}
export const generateCode = () => {
  const char=["A","B","C","D","E","F","G","H","J","K","L","M","N","P","R","W","X","Y"]

  const code = char[Math.floor((Math.random() * char.length) + 0)] +'-'+ Math.floor((Math.random() * 999999) + 100000)
  return code
}

export const AMPM = (time:any) => {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}

export const getFiatName = (id: any,processors:Proccesor[]) => {
  return processors?.find(p => p?.id === id)?.systemProcessorRange![0]?.systemFiat?.name || '-'
}

export const validateRouteRole = (role:string) => {
  const permissions:permissionsRole[] = [
    {
      type: "moderator",
      enableRoutes: ["/admin","/admin/blog","/admin/blog/guardar"]
    },
    {
      type: "admin",
      enableRoutes: ["*"]
    },
    {
      type: "cajero",
      enableRoutes: ["/admin","/admin/depositos","/admin/depositos/"]
    },
    {
      type: "cajerobolivares",
      enableRoutes: ["/admin","/admin/depositos-bolivares","/admin/depositos/detalle"]
    },    
    {
      type: "user",
      enableRoutes: []
    }
  ]
  return permissions.find(p => p.type === role)?.enableRoutes || []
}

export  const customControlStyles: CSSProperties = {
  boxShadow: 'none',
  color: '#113843',
  height: '50px',
  width: '100%',
}
type IsMulti = false
export const selectStyle: StylesConfig<MyOptionType, IsMulti> = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: '#FFF',
    color: state.isSelected ? '#D1D5DB' : '#113843',
    height: 'auto',
    '&:hover': {
      backgroundColor: '#FDEADD',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#FFF',
    borderColor: state.isFocused ? '#D1D5DB' : '#D1D5DB',
    borderRadius: '5px',
    fontWeight: '600',
    boxShadow: state.isFocused ? '#D1D5DB' : '#D1D5DB',
    '&:hover': {
      borderColor: state.isFocused ? '#D1D5DB' : '#D1D5DB',
      boxShadow: 'none',
    },
    height: '50px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#113843',
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  container: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    }
  },
}