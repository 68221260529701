import { Dialog, Transition } from "@headlessui/react";

import { Fragment, useEffect, useState } from "react";
import { ImSpinner9 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { UseAuth } from "src/auth/AuthContext";
import APIService from "src/services/Api";

const TermsnConditionUpdate = () => {
  let [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate()
  const { user, setUserData} = UseAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const handleAccept = async (res:boolean) => {
    if(res){
      setLoading(true)
      const res = await APIService.userCheckTermsnCondition({userId: user?.id || '',action:'accept'})
      console.log(res)
      if(res?.data?.data?.update.affected === 1){
        setIsOpen(false)
        if(user){
          setUserData(user.id,
            user.email,
            user?.fullname || '',
            user.phone,
            user?.country || '',
            user?.birthday || '',
            user.verifyAccount,
            user?.role || '',
            user.verifiedIdentity,
            true)
        }
      }
      setLoading(false)
    }else{
      setLoading(true)
      navigate('/logout-secure')
    }
  }

  useEffect(() => {

      /*if(user?.verifiedIdentity || false){
        window.location.reload()
      }*/
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          style={{ zIndex: "99999" }}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="text-xl font-bold tezt-center md:text-left flex items-center gap-4">
                    Hola!, hemos actualizado nuestros Términos y Condiciones.
                    {loading &&
                      <div className="flex items-center justify-center py-4 px-4">
                        <ImSpinner9 size={22} className="mx-auto text-green-admin-light animate-spin" />
                      </div>
                    }
                  </div>
                  <div>Intercambios Sin Fronteras sas</div>
                  <div className="body-scroll-dialog border border-stone-400 rounded-sm px-2 py-2">
                    <div className="mt-4 border-t border-stone-400 text-center tracking-wide font-medium py-4">
                      <div className="mb-2">
                        Entre los suscritos a saber usuario <b>{user?.fullname}</b>, mayor de edad y en
                        ejercicio, quien de ahora en
                        adelante se llamará Contratante 1 e INTERCAMBIOS SIN
                        FRONTERAS SAS con NIT 901.697.692-6, quien de ahora en
                        adelante se denominará CONTRATANTE 2 deciden acordar
                        mediante este contrato la compra y venta de instrumentos
                        financieros y Cryptomonedas que se regirán por las
                        siguientes condiciones y cláusulas:
                      </div>
                      <div className="mb-2">
                        PRIMERO OBJETO : mediante este servicio el contratante 2
                        ofrece la modalidad de comercio electrónico, consultoría
                        empresarial en diversos temas digitales, intercambios de
                        servicios en Criptomonedas y servicio como agente de
                        pagos de diversos brókeres usados para actividades en
                        Forex de divisas, metales, índices y criptoactivos
                        efectuados de forma online.
                      </div>
                      <div className="mb-2">
                        SEGUNDA VALOR Y FORMA DE PAGO: el valor de la operación
                        será la que resultase de común acuerdo y ante el
                        requerimiento previo del contratante 1 , al momento del
                        requerimiento, dicha cantidad deberá ser aceptada por el
                        contratante 2. El pago se realizará mediante
                        transferencia electrónica o en efectivo, a interés de
                        las partes , y el valor del cambio será el que se
                        encuentre registrado al momento de la transacción en la
                        página www.isfcambios.com que desde ya , el contratante
                        1 acepta.
                      </div>
                      <div className="mt-2">
                        Parágrafo: para la celebración de la transacción
                        cualesquiera que sea el monto esta deberá previamente
                        ser manifestada y acordada entre las partes mediante
                        mensaje de datos que acredite el efectivo recibido y
                        leído por parte de las partes.
                      </div>
                      <div className="mt-2">
                        TERCERO: DECLARACIÓN DE FONDOS: el contratante 1
                        manifiesta y Declara que los recursos que entregó
                        provienen de actividades lícitas, de conformidad con la
                        normatividad Colombiana.{" "}
                      </div>
                      <div className="mt-2">
                        Que no admitiré que terceros efectúen depósitos en sus
                        cuentas con fondos provenientes de las actividades
                        ilícitas contempladas en el Código Penal Colombiano o en
                        cualquier otra norma que lo adiciones; ni efectuará
                        transacciones destinadas a tales actividades o a favor
                        de personas relacionadas con las mismas.
                      </div>
                      <div className="mt-2">
                        Autoriza a resolver cualquier acuerdo, negocio o
                        contrato celebrado con celebrado con el CONTRATANTE 1,
                        en caso de infracción de cualquiera de los numerales
                        contenidos en este documento eximiendo al contratante 2
                        de toda responsabilidad que se derive por información
                        errónea, falsa o inexacta que se hubiere proporcionado
                        en este documento, o de la violación del mismo.{" "}
                      </div>
                      <div className="mt-2">
                        Que en la ejecución del contrato o el desarrollo de las
                        actividades en qué consiste el negocio jurídico, el
                        contratante 1 no contratará ni tendran vínculos de
                        ningún tipo con terceros que realicen operaciones o
                        cuyos recursos provengan de actividades ilícitas de las
                        contempladas en el Código Penal Colombiano o en
                        cualquier norma que lo sustituya, adicione, o modifique.{" "}
                      </div>
                      <div className="mt-2">
                        CUARTO: MANIFESTACION SARLAFT Que el contratante 1
                        manifiesta que cumple con las normas sobre prevención y
                        control al lavado de activos y financiación del
                        terrorismo (LA/FT) que le resulten aplicables ,teniendo
                        implementados las políticas, procedimientos y mecanismos
                        de prevención y control al LA(lavado de activos
                        )/FT(financiacion al terrorismo) que se derivan de
                        dichas disposiciones legales{" "}
                      </div>
                      <div className="mt-2">
                        Las partes manifiestan que no se encuentran en las
                        listas internacionales vinculantes para Colombia de
                        conformidad con el derecho internacional (listas de las
                        Naciones Unidas) o en las listas de la OFAC, estando EL
                        PATRIMONIO AUTÓNOMO facultada para efectuar las
                        verificaciones que considere pertinentes y para dar por
                        terminada cualquier relación comercial o jurídica si
                        verifica que me encuentro o que alguna de las personas
                        mencionadas figura en dichas listas.{" "}
                      </div>
                      <div className="mt-2">
                        QUINTO:CLÁUSULA COMPROMISORIA: cualquier conflicto que
                        se suscitare entre las partes , manifiestan estas que
                        será resuelto ante el tribunal de arbitramento de la
                        cámara de comercio de la ciudad de medellín , que ser
                        conformará por un árbitro que decidirá en derecho. Los
                        gastos de diligencia y conciliación serán sufragados por
                        la parte solicitante
                      </div>
                      <div className="mt-2">
                        SEXTO :PROCEDIMIENTOS Y/O PROTOCOLOS ADICIONALES: las
                        partes desde ya acuerdan que sera las consignadas en la
                        pagina https://www.isfcambios.com/terminos-y-condiciones
                        , estas en lo que conciernes a procedimientos
                        actualizaciones de precio y privacidad.
                      </div>
                      <div className="mt-2">
                        SÉPTIMO: confidencialidad tanto el contratante 1 como el
                        contratante 2 manifiestan que directamente o cualquiera
                        de sus filiales o subsidiarias no causará o requerirá
                        que ello viole cualquier obligación a el acuerdo, o la
                        confianza relacionada con confidencialidad, el secreto
                        de fabricación y la información propietaria con
                        cualquier otra persona, empresa o entidad. La violación
                        de esta condición causa la terminación automática de la
                        relación contractual desde el tiempo de violación. Si
                        las partes consideran que tiene investigaciones o
                        invenciones anteriores a la firma de este Acuerdo de
                        Confidencialidad que serán excluidas de este acuerdo, se
                        deberán enviar por correo electronico o via Whatsapp
                        servicio al cliente. con esto las partes se liberan de
                        cualquier reclamación.
                      </div>
                      <div className="mt-2">
                        Efectos. El presente contrato reemplaza y deja sin
                        efecto cualquier otro acuerdo, que se hubiera celebrado
                        entre las partes con anterioridad.
                      </div>

                      <div className="flex flex-col items-center justify-center mt-4 mb-4">
                        {!loading ?
                        <>
                          <button 
                            onClick={() => handleAccept(true)}
                            className="bg-white border border-stone-400 rounded-md shadow-md mb-4 mt-4 text-xl px-4 py-2"> 
                              Aceptar terminos y condiciones
                            </button>
                          <button 
                            onClick={() => handleAccept(false)}
                            className="bg-white border border-stone-400 rounded-md shadow-md mb-4 mt-2 text-xl px-4 py-2">
                              No aceptar, cerrar sesión
                            </button>
                        </>
                        :
                        <>
                          <div className="flex items-center justify-center py-4 px-4">
                            <ImSpinner9 size={52} className="mx-auto text-green-admin-light animate-spin" />
                          </div>
                        </> 
                        }
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default TermsnConditionUpdate;
